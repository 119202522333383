import React from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import NewSession from './New';
import List from './List';
import Details from './Details';
import { CAMERA_TO_EDIT_SESSION_STEPS } from '../../constants';
import { onCameraToEditView } from '../../duck/actions';

// const initialState = {
//   view: null,
//   active: null,
//   details: null,
// };

// const reducer = (state, action) => {
//   const { key, payload } = action;
//   switch (key) {
//     case 'VIEW':
//       return { ...state, view: payload };

//     case 'ACTIVE':
//       return { ...state, active: payload };

//     case 'UPDATE_ACTIVE':
//       return {
//         ...state,
//         active: {
//           ...state.active,
//           [payload.c2e_session_db_id]: { ...payload },
//         },
//       };

//     case 'DETAILS':
//       return { ...state, details: payload };

//     default:
//       break;
//   }
//   return state;
// };

export default function SidebarSession({
  toggleSidebar,
  closeSidebar,
  toggleToast,
  match,
}) {
  // const [state, dispatch] = useReducer(reducer, initialState);
  const dispatch = useDispatch();
  const sessionDetails = useSelector(
    store => store.storyboard.cameraToEditSessions
  );

  const COMMON_PROPS = {
    match,
    toggleSidebar,
    closeSidebar,
    toggleToast,
    goTo: step => dispatch(onCameraToEditView({ data: step })),
  };

  const renderScreen = index => {
    let component = null;

    switch (index) {
      case CAMERA_TO_EDIT_SESSION_STEPS.new: {
        component = <NewSession {...COMMON_PROPS} />;
        break;
      }

      case CAMERA_TO_EDIT_SESSION_STEPS.list: {
        component = <List {...COMMON_PROPS} />;
        break;
      }

      case CAMERA_TO_EDIT_SESSION_STEPS.details: {
        component = <Details {...COMMON_PROPS} />;
        break;
      }

      default:
        break;
    }

    return component;
  };

  return <>{renderScreen(sessionDetails?.view)}</>;
}

SidebarSession.propTypes = {
  toggleSidebar: PropTypes.func,
  closeSidebar: PropTypes.func,
  toggleToast: PropTypes.func,
  view: PropTypes.string,
  match: PropTypes.instanceOf(Object),
  pusher: PropTypes.instanceOf(Object),
};
