/* eslint-disable no-fallthrough */
/* eslint-disable indent */
import { SELECTION_KEYS } from '../constants';
import types from './types';

const INITIAL_STATE = {
  assets: {},
  details: {},
  members: {},
  tasks: [],
  cloudAccessDetails: {},
  assignedTaskViewFromHome: null,
  selectedTask: {
    details: {},
    messages: {},
  },
  listView: false,
  pageNo: 0,
  transfers: null,
  selectedItems: null,
  selectedFilters: null,
  cameraToEditSessions: {
    active: null,
    details: null,
    view: null,
  },
};

const storyboardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.LOAD_ASSETS: {
      let updatedState = {};
      if (action.data.type === 'update') {
        const updated = action.data.payload.results.filter(
          item =>
            !state.assets.results.some(
              el => item.ingest_db_id === el?.ingest_db_id
            )
        );
        // updated variable is to sync assets after delete pusher
        updatedState = {
          ...state,
          assets: {
            ...state.assets,
            page_no: action.data.payload.page_no,
            results: [...state.assets.results, ...updated],
          },
        };
      } else if (action.data.type === 'list_view') {
        updatedState = {
          ...state,
          assets: {
            ...state.assets,
            page_no: action.data.payload.page_no,
            results: action.data.payload.results,
          },
        };
      } else if (action.data.type === 'title_update_breadcrumbs') {
        const updatedBreadcrumbs = state.assets.breadcrumbs;
        updatedBreadcrumbs[0].name = action.data.payload;
        updatedState = {
          ...state,
          assets: {
            ...state.assets,
            breadcrumbs: updatedBreadcrumbs,
          },
        };
      } else {
        updatedState = {
          ...state,
          assets: { ...state.assets, ...action.data.payload },
        };
      }
      return updatedState;
    }

    case types.LIST_VIEW: {
      if (action.payload === 'list_view') {
        return {
          ...state,
          selectedItems: null,
          listView: true,
        };
      }
      return {
        ...state,
        selectedItems: null,
        listView: false,
      };
    }

    case types.LOAD_DETAILS: {
      // selected storyboard details
      return {
        ...state,
        details: { ...state.details, ...action.payload },
      };
    }

    case types.LOAD_MEMBERS: {
      // store collaborated users
      return {
        ...state,
        members: action.payload,
      };
    }

    case types.UPDATE_UPLOAD_FILE: {
      const filesArr = Object.assign([], state.assets.results);
      const index = filesArr.findIndex(file => file.ingest_db_id === action.id);
      if (index > -1) {
        filesArr[index] = {
          ...filesArr[index],
          ...action.data,
        };
      }
      return {
        ...state,
        assets: {
          ...state.assets,
          results: filesArr,
        },
      };
    }

    case types.UPDATE_ASSETS: {
      const { type, data } = action.data;
      let updatedState = {};

      if (type === 'add') {
        updatedState = { ...state };

        if (
          updatedState.assets.results.filter(
            el => el.ingest_db_id === data?.ingest_db_id
          )?.length <= 0
        ) {
          updatedState.assets.results = [data, ...state.assets.results];
        }
      }

      if (type === 'update') {
        updatedState = {
          ...state,
          assets: {
            ...state.assets,
            results: state.assets.results.map(clip => {
              if (clip.ingest_db_id === data.ingest_db_id) {
                return data;
              }
              return clip;
            }),
          },
        };
      }
      if (type === 'delete') {
        const exisitingTotalEntries = state.assets.total_entries;
        updatedState = {
          ...state,
          assets: {
            ...state.assets,
            total_entries: data?.no_of_assets || exisitingTotalEntries,
            results: state.assets.results.filter(
              clip => clip.ingest_db_id !== data.ingest_db_id
            ),
          },
        };
      }

      if (type === 'count_update') {
        updatedState = { ...state };
        updatedState.assets.total_entries = data?.no_of_assets;
        return updatedState;
      }

      // if (type === 'asset_folder_size') {
      //   const results = [...state.assets.results];
      //   const index = results.findIndex(
      //     el => el.ingest_db_id === data.ingest_db_id
      //   );

      //   results[
      //     index
      //   ].file_size = `Count: ${data?.asset_count}, Size: ${data?.file_size}`;

      //   if (index >= 0) {
      //     updatedState = {
      //       ...state,
      //       assets: {
      //         ...state.assets,
      //         results,
      //       },
      //     };
      //   }
      // }

      return updatedState;
    }

    case types.LOAD_ALL_TASK: {
      const { data, key } = action.payload;
      // load selected task
      if (!key) {
        return {
          ...state,
          tasks: data,
        };
      }
      if (key === 'update') {
        const updated = [...state.tasks];
        const index = updated.findIndex(
          item => item.task_db_id === data.task_db_id
        );
        updated[index] = data;
        return {
          ...state,
          tasks: [...updated],
        };
      }
      if (key === 'unread') {
        const updated = [...state.tasks];
        updated[data.index].unread_messages = data.unread;
        return {
          ...state,
          tasks: [...updated],
        };
      }
    }

    case types.LOAD_SELECTED_TASK: {
      // store selected task
      return {
        ...state,
        selectedTask: {
          ...state.selectedTask,
          details: action.payload,
        },
      };
    }

    case types.LOAD_MESSAGES: {
      // store messages of selected task

      const { data, type } = action.payload;

      if (!type) {
        return {
          ...state,
          selectedTask: {
            ...state.selectedTask,
            messages: data,
          },
        };
      }
      if (type === 'add') {
        const updated = [...state.selectedTask.messages.messages];
        const index = updated.findIndex(item => item.label === 'Today');
        if (index > -1) {
          updated[index].messages = [...updated[index].messages, data];
        } else {
          updated[updated.length || 0] = {
            label: 'Today',
            messages: [data],
          };
        }
        return {
          ...state,
          selectedTask: {
            ...state.selectedTask,
            messages: {
              ...state.selectedTask.messages,
              messages: updated,
            },
          },
        };
      }

      if (type === 'scroll_load') {
        const { messages, ...otherProps } = data;
        const existing = [...state.selectedTask.messages.messages];

        const updated = messages.reverse().reduce((acc, curr) => {
          const index = acc.findIndex(item => item.label === curr.label);
          if (index > -1) {
            acc[index].messages = [...curr.messages, ...acc[index].messages];
          } else {
            acc = [curr, ...acc];
          }
          return acc;
        }, existing);

        return {
          ...state,
          selectedTask: {
            ...state.selectedTask,
            messages: {
              ...state.selectedTask.messages,
              ...otherProps,
              messages: updated,
            },
          },
        };
      }
    }

    case types.LOAD_CLOUD_ACCESS_DETAILS: {
      // store all task
      return {
        ...state,
        cloudAccessDetails: action.payload,
      };
    }

    case types.TOGGLE_ASSIGNED_TASK_VIEW: {
      // store all task
      return {
        ...state,
        assignedTaskViewFromHome: action.payload || null,
      };
    }

    case types.RESET_STORYBOARD_STATE: {
      let newState = {
        ...INITIAL_STATE,
        listView: state.listView,
      };
      if (action.action === 'assets') {
        newState = {
          ...state,
          assets: {},
        };
      }

      return newState;
    }

    case types.LOAD_IMPORT_ASSET_STATUS: {
      const { type, data } = action?.payload;

      if (type === 'pagination') {
        const updated = { ...state?.transfers };
        updated.page_no = data?.page_no;
        updated.transfers = [
          ...state?.transfers?.transfers,
          ...data?.transfers,
        ];

        return {
          ...state,
          transfers: { ...updated },
        };
      }

      return {
        ...state,
        transfers: data || null,
      };
    }

    case types.SELECTED_ITEMS: {
      const { type, data } = action?.payload;
      let copy = { ...state?.selectedItems };

      if (type === SELECTION_KEYS.all) {
        copy = { ...data };
      }

      if (type === SELECTION_KEYS.selectItem) {
        const { ingest_db_id, ...rest } = data;
        copy[ingest_db_id] = { ingest_db_id, ...rest };
      }

      if (type === SELECTION_KEYS.removeItem) {
        const { ingest_db_id } = data;
        delete copy[ingest_db_id];

        if (Object.keys(copy)?.length === 0) {
          copy = null;
        }
      }

      if (type === SELECTION_KEYS.reset) {
        copy = null;
      }

      return { ...state, selectedItems: copy };
    }

    case types.SELECTED_FILTERS: {
      const { type, data } = action.payload;

      let updatedFilters = { ...state.selectedFilters };

      if (type === 'update') {
        const { key, item } = data;
        updatedFilters = {
          ...updatedFilters,
          [key]: { ...item },
        };
      }

      if (type === 'reset') {
        updatedFilters = null;
      }

      return { ...state, selectedFilters: updatedFilters };
    }

    case types.CAMERA_TO_EDIT_SESSION_VIEW: {
      const { data } = action.payload;
      const updated = { ...state.cameraToEditSessions };
      updated.view = data;
      return {
        ...state,
        cameraToEditSessions: updated,
      };
    }

    case types.CAMERA_TO_EDIT_ACTIVE_SESSIONS: {
      const { type, data } = action.payload;
      const updated = { ...state.cameraToEditSessions };

      if (type === 'update') {
        updated.active[data.c2e_session_db_id] = { ...data };
      } else {
        updated.active = data;
      }

      return {
        ...state,
        cameraToEditSessions: updated,
      };
    }

    case types.CAMERA_TO_EDIT_SESSION_DETAILS: {
      const { data } = action.payload;
      const updated = { ...state.cameraToEditSessions };
      updated.details = data;
      return {
        ...state,
        cameraToEditSessions: updated,
      };
    }

    default:
      return state;
  }
};

export default storyboardReducer;
