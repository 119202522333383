import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ArrowBackOutlined, FolderOpen, MoreHoriz } from '@material-ui/icons';
import { useParams } from 'react-router-dom';

// Components
import { useForm, TextField } from '../../components/FormInputs';
import CustomMenu from '../../components/menu';
import {
  MaterialAutocompleteStyles,
  StyledPaper,
  StyledUser,
} from '../../components/FormInputs/autoComplete';
import { Fab, Normal } from '../../components/Button';
import AssetOverview from './AssetOverview';
import {
  getProjectsFolderCopyMoveService,
  copyMoveAssetService,
} from '../duck/operations';
import { enqueueSnackbar } from '../../snackbar/duck/actions';
import { selectItemsAction } from '../duck/actions';
import { SELECTION_KEYS } from '../constants';
import { required } from '../../components/FormInputs/helperFunctions';
import { formatLabelValue } from '../../helperFunctions';

// Styles
import { Body, Footer } from '../../styles/modal';
import {
  Text,
  IconBreadCrumbStyles,
  BreadCrumbTextStyles,
} from '../../styles/common';
import { colors, font } from '../../styles/variables';
import { whiteAlpha } from '../../styles/utils';
import Tooltip from '../../components/tooltip';

const MenuStyledPaper = styled(StyledPaper)`
  &.MuiPaper-root {
    max-height: 15rem;
    overflow-y: auto;
  }
`;

const FoldersParentListStyles = styled.ul`
  list-style: none;
  max-height: 20rem;
  overflow-y: auto;

  > li {
    align-items: start;
    button {
      background: transparent;
      text-align: left;
      cursor: pointer;
      border: none;
      padding: 0.5rem 0;
      color: ${colors?.white};
      font-weight: ${font.normal};
      &:hover {
        color: ${colors?.blue?.primary};
      }
    }
  }
`;

const BreadcrumbStyles = styled(IconBreadCrumbStyles)`
  margin-bottom: 1.5rem;

  .project-btn {
    background: transparent;
    border: none;
    cursor: pointer;
    p {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .options {
    .MuiSvgIcon-root {
      margin-left: 0.5rem;
      width: 2.2rem;
      height: 2.2rem;
      fill: ${colors.white};
    }
  }

  .MuiFab-root {
    width: 2.5rem;
    height: 2.5rem;

    .MuiSvgIcon-root {
      font-size: 1.3rem;
    }
  }

  p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
    padding: 0;
    margin-left: 0.5rem;
  }
`;

const DESTINATION_TYPES = {
  project: 'project',
  folder: 'folder',
};

const ACTION_TYPES = {
  copy: 'copy',
  move: 'move',
};

const INITIAL_STATE = {
  selectedProject: {
    value: '',
    error: undefined,
    required: true,
    validation: required,
  },

  selectedFolder: {
    value: '',
    error: undefined,
  },
};

const getSelectedFolders = filesFolders =>
  filesFolders.reduce((acc, curr) => {
    if (curr.is_folder) {
      acc.push(curr.ingest_db_id);
    }
    return acc;
  }, []);

const CopyMoveToProjectFolder = ({ toggleModal, assetDetails }) => {
  const dispatch = useDispatch();
  const { project_db_id } = useParams();
  const selectedItems = useSelector(store => store?.storyboard?.selectedItems);
  const [projectInputValue, setProjectInputValue] = useState('');
  const [selectedFolders, setSelectedFolders] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const [folderOptions, setFolderOptions] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);

  const actionDestinationDetails = {
    action: assetDetails?.action || ACTION_TYPES.copy,
    destination: DESTINATION_TYPES.project,
  };

  const { formValues, onSubmit, updateValues } = useForm(
    INITIAL_STATE,
    async () => {
      setSubmitting(true);
      const {
        data: { response, errormessage, data },
      } = await copyMoveAssetService({
        ingest_db_ids: assetDetails?.isMultiple
          ? Object.keys(selectedItems)
          : [assetDetails?.ingest_db_id],
        action: actionDestinationDetails?.action,
        destination_project_db_id: formValues.selectedProject.value?.value,
        destination_folder_db_id: formValues.selectedFolder.value?.value ?? '',
        source_project_db_id: project_db_id,
      });
      setSubmitting(false);
      if (response) {
        if (assetDetails?.isMultiple) {
          dispatch(
            selectItemsAction({
              type: SELECTION_KEYS.reset,
            })
          );
        }
        dispatch(
          enqueueSnackbar({
            message: {
              messageHead: 'Success',
              messageBody: data?.message,
              variant: 'success',
            },
          })
        );
        toggleModal(null, false, null);
      } else {
        dispatch(
          enqueueSnackbar({
            message: {
              messageHead: 'Error',
              messageBody: errormessage,
              variant: 'error',
            },
          })
        );
      }
    }
  );

  const fetchProjectsFolder = async (type, value, projectValue) => {
    const updatedDestionation = type ?? actionDestinationDetails?.destination;
    const request = {
      action: actionDestinationDetails?.action,
      destination: updatedDestionation,
      source_folder_db_ids:
        getSelectedFolders(
          assetDetails?.isMultiple
            ? Object.values(selectedItems)
            : [assetDetails]
        )?.join(',') ?? '',
      project_db_id: projectValue || value || project_db_id,
      ...(updatedDestionation === DESTINATION_TYPES.folder &&
        projectValue && {
          folder_db_id: value,
        }),
    };

    const {
      data: { response, data },
    } = await getProjectsFolderCopyMoveService(request);
    if (response) {
      if (updatedDestionation === DESTINATION_TYPES.project) {
        const options = formatLabelValue(
          data?.results,
          'title',
          'project_db_id'
        );
        setProjectOptions(options);
      } else {
        const options = formatLabelValue(data?.results, 'path', 'folder_db_id');
        setFolderOptions(options);
      }
    }
  };

  const onProjectChange = (e, option, reason) => {
    if (reason === 'select-option') {
      setFolderOptions([]); // reset
      fetchProjectsFolder('project', option?.value);
      fetchProjectsFolder('folder', option?.value);
      updateValues({
        ...formValues,
        selectedProject: {
          ...formValues.selectedProject,
          value: option,
          error: undefined,
        },
        selectedFolder: {
          ...formValues.selectedFolder,
          value: '',
        },
      });
    }
  };

  const onBreadcrumbProject = () => {
    fetchProjectsFolder('folder', formValues?.selectedProject?.value?.value);
    setSelectedFolders([]);
    updateValues({
      ...formValues,
      selectedFolder: {
        ...formValues.selectedFolder,
        value: '',
      },
    });
  };

  const onFolderSelect = useCallback(
    (option, add) => {
      const updatedOption = {
        label: option?.name || option?.label || '',
        value: option?.value || option?.field || '',
      };

      updateValues({
        ...formValues,
        selectedFolder: {
          ...formValues.selectedFolder,
          value: updatedOption,
          error: undefined,
        },
      });

      fetchProjectsFolder(
        'folder',
        (option?.value || option?.field) ?? '',
        formValues?.selectedProject?.value?.value
      );

      if (!add) {
        if (option?.index?.toString()) {
          const updated = option.index - 1;
          if (updated || updated.toString() === '0') {
            setSelectedFolders(selectedFolders.slice(0, option.index));
          } else {
            setSelectedFolders(selectedFolders.slice(0, -1));
          }
        } else {
          setSelectedFolders(selectedFolders.slice(0, -1));
        }
      } else {
        setSelectedFolders(prev => [...prev, option]);
      }
    },
    [selectedFolders, formValues?.selectedProject?.value]
  );

  useEffect(() => {
    fetchProjectsFolder();
  }, []);

  const onBack = useCallback(() => {
    const copy = [...selectedFolders];
    const lastTwoElement = copy.slice(-2);
    onFolderSelect(
      lastTwoElement?.length === 2 ? lastTwoElement[0] : undefined
    );
  }, [selectedFolders]);

  const formatedSelectedFolders = useMemo(() => {
    const copy = [...selectedFolders];
    return copy.slice(0, -1).map((el, index) => ({
      index: index + 1,
      name: el.label,
      field: el.value,
      isSelected: false,
      icon: <FolderOpen style={{ marginRight: '0.5rem' }} />,
    }));
  }, [selectedFolders]);

  return (
    <form onSubmit={onSubmit} name='copy-move-assets'>
      <Body style={{ padding: '4rem 0 3.5rem 0' }}>
        {(formValues?.selectedFolder?.value?.value ||
          selectedFolders?.length > 0) && (
          <BreadcrumbStyles>
            <Fab
              type='button'
              onClick={onBack}
              size='small'
              color='primary'
              aria-label='back'
              icon={<ArrowBackOutlined />}
            />
            <Tooltip
              title={formValues?.selectedProject?.value?.label}
              hide
              placement='top'
              arrow>
              <button
                type='button'
                className='project-btn'
                onClick={onBreadcrumbProject}>
                <span>
                  <BreadCrumbTextStyles className='first'>
                    {formValues?.selectedProject?.value?.label}
                  </BreadCrumbTextStyles>
                </span>
              </button>
            </Tooltip>
            {selectedFolders?.length > 1 && (
              <>
                <Text>/</Text>
                <div className='options'>
                  <CustomMenu
                    maxHeight='20rem'
                    menuItemTextTransform={false}
                    onMenuItemClick={item => onFolderSelect(item)}
                    menuList={formatedSelectedFolders}
                    icon={<MoreHoriz />}
                  />
                </div>
              </>
            )}
            <Text>/</Text>
            <Tooltip
              title={selectedFolders[selectedFolders?.length - 1]?.label}
              hide
              placement='top'
              arrow>
              <BreadCrumbTextStyles className='last'>
                {selectedFolders[selectedFolders?.length - 1]?.label}
              </BreadCrumbTextStyles>
            </Tooltip>
          </BreadcrumbStyles>
        )}

        {!assetDetails?.isMultiple && (
          <div style={{ marginBottom: '1rem' }}>
            <AssetOverview {...assetDetails} />
          </div>
        )}

        <MaterialAutocompleteStyles
          id='selectedProject'
          disableClearable
          autoHighlight
          getOptionLabel={option => option.label ?? ''}
          onChange={onProjectChange}
          options={projectOptions}
          disabled={
            actionDestinationDetails?.destination === DESTINATION_TYPES.folder
          }
          PaperComponent={paperProps => <MenuStyledPaper {...paperProps} />}
          getOptionSelected={(option, value) => option.value === value.value}
          inputValue={projectInputValue}
          onInputChange={(event, newInputValue) => {
            setProjectInputValue(newInputValue);
          }}
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
              label='Select Project'
              error={!!formValues.selectedProject.error}
              helperText={formValues.selectedProject.error}
              value={formValues.selectedProject?.value?.value ?? ''}
            />
          )}
          renderOption={option => {
            return (
              <StyledUser>
                <Text color={colors.black.default} padding='0'>
                  {option?.label}
                </Text>
              </StyledUser>
            );
          }}
        />
        <div aria-label='folders'>
          {folderOptions?.length > 0 && (
            <>
              <Text padding='1rem 0' color={whiteAlpha(0.7)}>
                Select Folder
              </Text>
              <FoldersParentListStyles>
                {folderOptions?.map(el => (
                  <li className='flex'>
                    <button
                      className='flex items-center w-full'
                      type='button'
                      key={el?.value}
                      value={el?.value}
                      onClick={() => onFolderSelect(el, 'push')}>
                      <span>
                        <FolderOpen style={{ marginRight: '0.5rem' }} />
                      </span>
                      <span>{el?.label}</span>
                    </button>
                  </li>
                ))}
              </FoldersParentListStyles>
            </>
          )}

          {/* {formValues?.selectedProject?.value?.value && !folderOptions?.length && (
            <Text padding='1rem 0' color={whiteAlpha(0.7)}>
              No Folders Found
            </Text>
          )} */}
        </div>
      </Body>

      <Footer>
        <Normal
          onClick={() => toggleModal(null, false, null)}
          color='secondary'
          style={{ marginRight: '2rem' }}>
          Cancel
        </Normal>
        <Normal
          type='submit'
          disabled={isSubmitting || !formValues.selectedProject.value?.value}
          isLoading={isSubmitting}>
          {actionDestinationDetails?.action === ACTION_TYPES.copy
            ? 'Copy'
            : 'Move'}
        </Normal>
      </Footer>
    </form>
  );
};

CopyMoveToProjectFolder.propTypes = {
  toggleModal: PropTypes.func,
  assetDetails: PropTypes.instanceOf(Object),
};

export default CopyMoveToProjectFolder;
