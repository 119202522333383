/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import { LinkedCamera } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import sidebarTypes from '../../../components/Sidebar/constants';
import { CAMERA_TO_EDIT_SESSION_STEPS } from '../../constants';
import { toggleSidebar } from '../../../main-wrapper/duck/actions';
import { cameraSessionsService } from '../../duck/operations';

// Styles
import { colors } from '../../../styles/variables';
import {
  onCameraToEditActiveSessions,
  onCameraToEditSessionDetails,
  onCameraToEditView,
} from '../../duck/actions';

const CardStyles = styled.div`
  background-color: ${({ bgColor }) => bgColor};
  border: none;
  margin: 0;
  box-shadow: 0 1.2rem 2.4rem 0 rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 17.4rem;
  width: 100%;
  height: 100%;
  border-radius: 0.4rem;
  cursor: pointer;

  .icon {
    width: 3.6rem;
    height: 3.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.2rem solid #fff;
    color: ${colors.white};
    border-radius: 100%;
    margin-bottom: 1.6rem;
    > svg {
      font-size: 2rem;
      position: relative;
    }
  }
  .card-header {
    padding: 0;
    margin-bottom: 0.9rem;
    font-size: 1.6rem;
    font-weight: 400;
  }
  .new-session {
    padding: 0;
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 1.5rem;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const CreateToEditCard = ({ pusher }) => {
  const { project_db_id } = useParams();
  const [details, setDetails] = useState(null);
  const dispatch = useDispatch();
  const session = useSelector(store => store.storyboard.cameraToEditSessions);
  const { view } = session;

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const res = await cameraSessionsService({
          project_db_id,
        });
        const {
          data: {
            data: { c2e_sessions, ...rest },
            response,
          },
        } = res;
        if (response) setDetails({ ...rest });
      } catch (err) {
        console.log('🚀 ~ fetchDetails ~ err:', err);
      }
    };
    fetchDetails();
  }, []);

  const onClick = e => {
    dispatch(
      toggleSidebar({
        show: true,
        type: sidebarTypes.cameraToEdit,
        title: 'Camera to Edit',
      })
    );
    dispatch(
      onCameraToEditView({
        data:
          e?.target?.id === 'new'
            ? CAMERA_TO_EDIT_SESSION_STEPS.new
            : CAMERA_TO_EDIT_SESSION_STEPS.list,
      })
    );
  };

  useEffect(() => {
    let channel = null;
    if (pusher && project_db_id) {
      channel = pusher.subscribe(`private-${project_db_id}`);
      if (channel) {
        channel.bind('c2e_sessions', ({ c2e_sessions, ...rest }) => {
          setDetails({ ...rest });
        });

        channel.bind('c2e_session_update', data => {
          if (view === 'DETAILS') {
            dispatch(onCameraToEditSessionDetails({ data }));
          } else if (view === 'LIST') {
            dispatch(onCameraToEditActiveSessions({ type: 'update', data }));
          }
        });
      }
    }

    return () => {
      if (channel && pusher) {
        channel.unbind();
        pusher.unsubscribe();
      }
    };
  }, [pusher, view, project_db_id]);

  return (
    <CardStyles
      role='button'
      onClick={onClick}
      bgColor={details?.is_session_active ? '#18B96E' : '#6faaff'}>
      <div className='icon'>
        <LinkedCamera />
      </div>
      <p className='card-header'>Camera To Edit</p>
      {details?.is_session_active ? (
        <p className='new-session'>
          View Active Sessions ({`${details?.session_count}`})
        </p>
      ) : (
        <p className='new-session' id='new'>
          Create C2E Session
        </p>
      )}
    </CardStyles>
  );
};

CreateToEditCard.propTypes = {
  pusher: PropTypes.instanceOf(Object),
};

export default CreateToEditCard;
