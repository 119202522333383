import types from './types';

const actionLoadAssets = (payload, type) => {
  return {
    type: types.LOAD_ASSETS,
    data: {
      payload,
      type,
    },
  };
};

const actionListView = payload => {
  return {
    type: types.LIST_VIEW,
    payload,
  };
};

const actionLoadDetails = payload => {
  return {
    type: types.LOAD_DETAILS,
    payload,
  };
};

const loadCollaboratedUsers = payload => {
  return {
    type: types.LOAD_MEMBERS,
    payload,
  };
};

const updateAssetsData = (data, type) => {
  return {
    type: types.UPDATE_ASSETS,
    data: {
      data,
      type,
    },
  };
};

const loadTaskTypes = payload => {
  return {
    type: types.LOAD_TASK_TYPES,
    payload,
  };
};

const loadEligibleUsers = payload => {
  return {
    type: types.LOAD_ELIGIBLE_USERS,
    payload,
  };
};

const loadSelectedTaskDetails = payload => {
  return {
    type: types.LOAD_SELECTED_TASK,
    payload,
  };
};

const loadAllTaskDetails = payload => {
  return {
    type: types.LOAD_ALL_TASK,
    payload,
  };
};

const loadCloudAccessSystemDetails = payload => {
  return {
    type: types.LOAD_CLOUD_ACCESS_DETAILS,
    payload,
  };
};

const loadMessages = payload => {
  // payload is object which contains data, type
  // type = add || update || all
  // by default is all
  return {
    type: types.LOAD_MESSAGES,
    payload,
  };
};

const assignedTaskFromHome = payload => {
  return {
    type: types.TOGGLE_ASSIGNED_TASK_VIEW,
    payload,
  };
};

export const setImportAssetStatus = payload => ({
  type: types.LOAD_IMPORT_ASSET_STATUS,
  payload,
});

export const resetState = action => {
  return {
    type: types.RESET_STORYBOARD_STATE,
    action,
  };
};

export const selectItemsAction = payload => ({
  type: types.SELECTED_ITEMS,
  payload,
});

export const selectFiltersAction = payload => ({
  type: types.SELECTED_FILTERS,
  payload,
});

export const onCameraToEditView = payload => ({
  type: types.CAMERA_TO_EDIT_SESSION_VIEW,
  payload,
});

export const onCameraToEditActiveSessions = payload => ({
  type: types.CAMERA_TO_EDIT_ACTIVE_SESSIONS,
  payload,
});

export const onCameraToEditSessionDetails = payload => ({
  type: types.CAMERA_TO_EDIT_SESSION_DETAILS,
  payload,
});

export default {
  actionLoadAssets,
  actionListView,
  actionLoadDetails,
  loadCollaboratedUsers,
  updateAssetsData,
  loadTaskTypes,
  loadEligibleUsers,
  loadSelectedTaskDetails,
  loadAllTaskDetails,
  loadCloudAccessSystemDetails,
  resetState,
  loadMessages,
  assignedTaskFromHome,
  setImportAssetStatus,
  selectItemsAction,
};
